import {
    Form,
    Input,
    Button,
    Select,
    Typography,
    Result,
} from "antd";

import {useState} from "react";
import {useForm} from "antd/lib/form/Form";
import {
    signularisApi,
    useCreateMetaDocumentMutation,
    useGetRecipientsListQuery, useGetSelfInfoQuery
} from "../../../store/api/signularisApi";
import {useSelector} from "react-redux";

import genDocument1 from '../misc/gendoc1';
import {RootState} from "../../../store/store";
import {useAppDispatch} from "../../../hooks/redux";
import {CREATE_DOCUMENT_ROUTE, MAIN_PAGE_ROUTE} from "../../../routing/consts";
import {Link} from "react-router-dom";
import {DocTemplateType} from "../../../types/types";

const {Title} = Typography;
const {Item} = Form;
const {TextArea} = Input;
const {Option} = Select;

type TemplateRequest = {
    title: string,
    brief: string,
    recipients: string[],
    file: File,
    docTitle: string,
    regdate: string,
    regnum: string,
    regtext: string,
    dutyface: string
}

//generates random id;
let guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x100)
            .toString(10)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + s4() + s4() + s4();
}

function styledDate() {
    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля",
        "августа", "сентября", "октября", "ноября", "декабря"]
    const dobj = new Date()
    return `${dobj.getDate()} ${months[dobj.getMonth()]} ${dobj.getFullYear()}`
}

type DocumentTitle1 = 'Ректору ГГНТУ \nМинцаеву М. Ш.' | 'Проректору по АХЧ \nЧегарбиеву А.С'
export const documentTitle1: DocumentTitle1[] = ['Ректору ГГНТУ \nМинцаеву М. Ш.', 'Проректору по АХЧ \nЧегарбиеву А.С']


function Template() {
    const info = useSelector((state: RootState) => state.userinfo)
    const dispatch = useAppDispatch()

    const {data: recipients} = useGetRecipientsListQuery()
    const {data: newUsInfo} = useGetSelfInfoQuery()

    const defaulRepicients = ["62567bcc72944c7123ebde5a", '62303f756315cc23e79ca2c9', newUsInfo?.id]
    const recipientsList = recipients?.filter(item => !defaulRepicients.includes(item.id)) || []

    const [docname, setDocname] = useState("")
    const docTemplate: DocTemplateType = 'extractStorage'

    const [createMetaDocument, {
        isLoading: mutationLoading,
        isSuccess: mutationSuccess,
        isError: mutationError,
    }] = useCreateMetaDocumentMutation()

    const [form] = useForm()

    const onFinish = async (values: TemplateRequest) => {
        // user info
        const regnum = guid()

        setDocname('Заявка на выписку из склада')
        const formData = new FormData()
        formData.append("title", `Заявка на выписку из склада №${regnum}`)
        formData.append("brief", "Сгенерировано из шаблона")
        formData.append("recipients", values.recipients.join(",") + ',' + defaulRepicients.join(","))
        formData.append("documentType", docTemplate)
        values.regnum = regnum

        values.regdate = styledDate()
        values.dutyface = `${info.lastname} ${info.firstname} ${info.middlename}`
        const pdfBytes = await genDocument1(values, info)

        formData.append("file", new Blob([pdfBytes]))
        createMetaDocument(formData)

        dispatch(signularisApi.util.invalidateTags(['MetaDocument']))
    }

    if (mutationSuccess) {
        return (
            <Result
                status="success"
                title="Документ создан"
                subTitle={`Ваш документ ${docname} успешно создан.`}
                extra={[
                    <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button>Создать еще</Button></Link>
                ]}
            />
        )
    }

    if (mutationError) {
        return (
            <Result
                status="error"
                title="Не удалось создать документ"
                extra={[
                    <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button>Создать еще</Button></Link>
                ]}
            />
        )
    }
    return (
        <>
            <Title level={2}>Выписка из склада</Title>
            <p style={{color: 'red', marginBottom: '30px', fontWeight: 'normal', fontSize: 14}}>
                При создании заявки на выписку компьютерного оборудования или оргтехники, необходимо в получателях
                указать проректора по ЦиИ ПАШАЕВА МАГОМЕДА ЯРАГИЕВИЧА. В остальных случаях указать КУРИРУЮЩЕГО
                ПРОРЕКТОРА.</p>
            <Form form={form} onFinish={onFinish} initialValues={{regtext: "Прошу Вас выписать со склада "}}>
                <Item  name="recipients"
                      rules={[{required: true, message: 'Обязательное поле'}]}>
                    <Select
                        mode="multiple"
                        maxTagCount='responsive'
                        allowClear
                        placeholder="Получатели"
                        notFoundContent={"Ничего не найдено"}
                        filterOption={(input, option) =>
                            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }

                    >
                        {
                            recipientsList?.map((recipient) => (
                                <Option key={recipient.id} value={recipient.id} label={recipient.fullname}>
                                    {recipient.fullname} {!!recipient.position && <>- <span
                                    style={{color: 'gray'}}>{recipient.position}</span></>}
                                </Option>
                            ))
                        }
                    </Select>
                </Item>
                <span style={{marginTop: 5, marginBottom: 25, display: 'block'}}><span style={{
                    display: 'inline-block',
                    backgroundColor: '#f5f5f5',
                    borderRadius: 5,
                    marginRight: 5,
                    padding: '2px 7px'
                }}>Магомедов Тимур Эейсаевич</span>
                    <span style={{
                        display: 'inline-block',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 5,
                        padding: '2px 7px'
                    }}>Чегарбиев Адлан Сулейманович</span>
                  </span>
                <Item style={{marginBottom: 10}} name="docTitle"
                      rules={[{required: true, message: 'Обязательное поле'}]}>
                    <Select
                        allowClear
                        placeholder={'Заявка на имя(кому)...'}
                        notFoundContent={"Ничего не найдено"}
                        filterOption={(input, option) =>
                            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            documentTitle1?.map((recipient) => (
                                <Option key={recipient} value={recipient} label={recipient}>
                                    {recipient}
                                </Option>
                            ))
                        }
                    </Select>
                </Item>
                <Item className='my-input' name="regtext" rules={[{required: true, message: 'Обязательное поле'}]}>
                    <TextArea rows={5} placeholder="Продолжение текста заявки"/>
                </Item>
                <Button className='my-btn margin-bottom-xl' type="primary" htmlType="submit" loading={mutationLoading}>Создать
                    документ</Button>
            </Form>
        </>
    )
}

export default Template;
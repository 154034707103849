import {
    Form,
    Button,
    Select,
    Input,
    Typography,
    Result, Skeleton, Alert, message, Upload,
} from "antd";

import {useMemo, useState} from "react";
import { useForm } from "antd/lib/form/Form";
import {
    signularisApi,
    useCreateMetaDocumentMutation,
    useGetMetaDocumentsQuery,
    useGetRecipientsListQuery
} from "../../../store/api/signularisApi";

import { useAppDispatch } from "../../../hooks/redux";
import {CREATE_DOCUMENT_ROUTE, MAIN_PAGE_ROUTE} from "../../../routing/consts";
import { Link } from "react-router-dom";
import {DocTemplateType, Document, GetMetaDocumentsRequest} from "../../../types/types";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import genDocument8 from "../misc/gendoc8";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { downloadPdfFileResponse } from "../../../utils/download-pdf";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

type TemplateRequest = {
    title:      string,
    brief:      string,
    recipients: string[],
    file:       File,
    reference: number,
    regdate: string,
    regnum: string,
    regtext: string,
    dutyface: string
    docTitle: string
}

//generates random id;
let guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x100)
            .toString(10)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + s4() + s4() + s4();
}

function styledDate() {
    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", 
                    "августа", "сентября", "октября", "ноября", "декабря"]
    const dobj = new Date()
    return `${dobj.getDate()} ${months[dobj.getMonth()]} ${dobj.getFullYear()}`
  }


type DocumentTitle1 = 'Ректору ГГНТУ \nМинцаеву М. Ш.' | 'Проректору по АХЧ \nЧегарбиеву А.С' | 'Проректору по ЦиИ Пашаеву М.Я.'
const documentTitle1: DocumentTitle1[] = ['Ректору ГГНТУ \nМинцаеву М. Ш.', 'Проректору по АХЧ \nЧегарбиеву А.С', 'Проректору по ЦиИ Пашаеву М.Я.']

function Template(prop : {referenceId?: string, referenceType?:string}) {
  const info = useSelector((state: RootState) => state.userinfo)
    const dispatch = useAppDispatch()

    let reqParams: GetMetaDocumentsRequest = {
        type: 'signed',
        skip: 0,
        limit: 450,
        sort: 'newest'
    }
    const [docListFiltred, setDocListFiltred] = useState(Array<Document>)
    

    const {data: docList, isLoading: docListIsLoading, isError: docListIsError, refetch: docListRefetch} = useGetMetaDocumentsQuery(reqParams)

    useMemo(()=>{
        if (Array.isArray(docList?.docs) && docList?.docs != undefined ){
            setDocListFiltred(docList?.docs.filter((item)=>{
                return item.documentType === 'technicalExaminationReport'
            }))
        }
    },[docList?.docs])

    
    const {data: recipients} = useGetRecipientsListQuery()

    const defaulRepicients = ["6374f9fac845f6fe85e4e3fa", '67175e6e1050798773fca7ff']
    const recipientsList = recipients?.filter(item => !defaulRepicients.includes(item.id)) || []


    const [docname, setDocname] = useState("")
    const docTemplate : DocTemplateType = 'applicationForPurchase'

    const [createMetaDocument, {
        isLoading: mutationLoading,
        isSuccess: mutationSuccess,
        isError: mutationError,
    } ] = useCreateMetaDocumentMutation()

    const [form] = useForm()


    const onFinish = async (values: TemplateRequest) => {
        // user info
        const regnum = guid()
        setDocname('Заявка на приобретение')
        const formData = new FormData()
        formData.append("title", `Заявка на приобретение  №${regnum}`)
        formData.append("brief", "Сгенерировано из шаблона")
        formData.append("recipients", values.recipients.join(",") + ',' + defaulRepicients.join(","))
        formData.append("referenceType", prop.referenceType ? prop.referenceType : docListFiltred[values.reference].documentType)
        formData.append("reference", prop.referenceId ? prop.referenceId : docListFiltred[values.reference].id)
        formData.append("documentType", docTemplate)
        values.regnum = regnum
        values.regdate = styledDate()
        const pdfBytes = await genDocument8(values,info)
        formData.append("file", new Blob([pdfBytes]))
        // downloadPdfFileResponse(new Blob([pdfBytes]));
        createMetaDocument(formData)
        dispatch(signularisApi.util.invalidateTags(['MetaDocument']))
    }

    if (mutationSuccess) {
        return (
            <Result
                status="success"
                title="Документ создан"
                subTitle={`Ваш документ ${docname} успешно создан из шаблона 'Заявка на приобретение'.`}
                extra={[
                    <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button >Создать еще</Button></Link>
                ]}
            />
        )
    }

    if (mutationError) {
        return (
            <Result
                status="error"
                title="Не удалось создать документ"
                extra={[
                    <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button >Создать еще</Button></Link>
                ]}
            />
        )
    }
    if (docListIsLoading){
        return (<>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            </>
        )
    }
    if (docListIsError){
        return (<>
                <Alert message="Произошла ошибка" type="error" />
                <Button onClick={docListRefetch} danger>Повторить запрос</Button>
            </>
        )
    }
    const formatTime = (time: string): string => {
        let tObj = new Date(time)
        let day = tObj.getDate().toString().length === 1 ? "0" + tObj.getDate() : tObj.getDate()
        let month = (tObj.getMonth()+1).toString().length === 1 ? "0" + (tObj.getMonth()+1 ): tObj.getMonth() +1
        let hours = tObj.getHours().toString().length === 1 ? "0" + tObj.getHours(): tObj.getHours()
        let minutes = tObj.getMinutes().toString().length === 1 ? "0" + tObj.getMinutes(): tObj.getMinutes()


        return `${day}.${month}.${tObj.getFullYear()} ${hours}:${minutes}`
    }

    return (
        <>
            <Title level={2} style={{ marginBottom: '30px' }}>Заявка на приобретение</Title>
            <Form form={form} onFinish={onFinish} >
                <Item style={{marginBottom: 0}} name="recipients" rules={[{ required: true, message: 'Обязательное поле' }]}>
                    <Select
                        mode="multiple"
                        maxTagCount='responsive'
                        allowClear
                        placeholder="Получатели"
                        notFoundContent={"Ничего не найдено"}
                        filterOption={(input, option) =>
                            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            recipientsList?.map((recipient) => (
                                <Option key={recipient.id} value={recipient.id} label={recipient.fullname}>
                                    {recipient.fullname} {!!recipient.position && <>- <span style={{color: 'gray'}}>{recipient.position}</span></>}
                                </Option>
                            ))
                        }
                    </Select>
                </Item>
                <span style={{marginTop: 5, marginBottom: 25, display: 'block'}}>
                        <span style={{
                        display: 'inline-block',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 5,
                        marginRight: 5,
                        padding: '2px 7px'
                        }}>Курбанов Зелимхан Магомедович</span>
                        <span style={{
                        display: 'inline-block',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 5,
                        padding: '2px 7px'
                        }}>Саидов Юсуп Умар-Хажиевич</span>
                    </span>
                <Item name="docTitle"
                        rules={[{required: true, message: 'Обязательное поле'}]}>
                    <Select
                        allowClear
                        placeholder={'Заявка на имя(кому)...'}
                        notFoundContent={"Ничего не найдено"}
                        filterOption={(input, option) =>
                            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            documentTitle1?.map((recipient) => (
                                <Option key={recipient} value={recipient} label={recipient}>
                                    {recipient}
                                </Option>
                            ))
                        }
                    </Select>
                </Item>
                {
                    prop.referenceId ? null : <Item name="reference" rules={[{ required: true, message: 'Обязательное поле' }]}>
                    <Select
                        allowClear
                        placeholder="Выберите акт, на который нужно ссылаться"
                        notFoundContent={"Ничего не найдено"}
                        filterOption={(input, option) =>
                            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            docListFiltred?.map((doc, index) => (
                                <Option key={doc.id} value={index} label={doc.documentTitle}>
                                    {doc.documentTitle}, от <span style={{fontStyle: 'italic'}}>{formatTime(doc.time)}</span>
                                </Option>
                            ))
                        }
                    </Select>
                </Item>
                }
                <Item className='my-input' name="regtext" rules={[{ required: true, message: 'Обязательное поле' }]}>
                    <TextArea placeholder="Текста заявки" rows={5}/>
                </Item>

                <Button className='my-btn margin-bottom-xl' type="primary"  htmlType="submit" loading={mutationLoading}>Создать документ</Button>
            </Form>
        </>
    )
}

export default Template;